import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxImage } from '../OxImage';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const Label = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [12, 12, 12], [15, 15, 15])}
        ${fluidSizing([
            { prop: 'margin-top', values: [20, 30, 30] },
            { prop: 'margin-bottom', values: [15, 20, 20] }
        ])}
    `
);

export const Header = styled.h1`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Bold, [28, 28, 28], [34, 34, 34])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [6, 12, 12] }])}
`;

export const Paragraph = styled.p`
    text-align: center;
    ${createTextStyle(EFonts.Hatton, EFontWeight.Light, [16, 16, 16], [22, 22, 22])}
    ${fluidSizing([
        { prop: 'margin-bottom', values: [14, 14, 14] },
        { prop: 'max-width', values: [220, null, null] }
    ])}
`;

export const Image = styled(OxImage)`
    width: 100%;
    ${fluidSizing([
        { prop: 'height', values: [275, 525, 525] },
        { prop: 'margin-top', values: [35, 50, 50] },
        { prop: 'margin-bottom', values: [35, 60, 60] }
    ])}
`;
